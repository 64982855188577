export enum DocumentStatus {
  Pending = "Pending",
  Uploading = "Uploading",
  AwaitingReview = "Awaiting review",
  Uploaded = "Uploaded",
  Completed = "Completed",
  Error = "Error",
}

export const UploadStatusUtil = {
  parse: (str: string): DocumentStatus => {
    let status = DocumentStatus.Pending;

    for (const value of Object.values(DocumentStatus)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        status = value;
      }
    }

    return status;
  },
};
