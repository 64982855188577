import React from "react";
import FlexBox from "../FlexBox/FlexBox";
import FormikTextInput from "../FormikTextInput/FormikTextInput";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import { useTranslation } from "react-i18next";
import { AddressUtil, IAddressEntity } from "models/Address.model";
import { useFormikContext } from "formik";

type FormikManualAddressInputProps = {
  isRequired: boolean;
};

const FormikManualAddressInput: React.FC<FormikManualAddressInputProps> = ({ isRequired }) => {
  const { values, setFieldValue } = useFormikContext<IAddressEntity>();

  const screenWidthMatch = useScreenWidthMatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    setFieldValue(
      "fullAddress",
      AddressUtil.generateFullAddressForManualAddress({
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zipCode: values.zipCode,
        isManualAddress: true,
        fullAddress: "",
      })
    );
  }, [setFieldValue, values]);

  return (
    <FlexBox direction="column" spacing={3} paddingBottom={2} data-testid={"ManualAddressInput"}>
      <FormikTextInput label={t(`Address line 1`)} fieldName="address1" required={isRequired} />
      <FormikTextInput label={t(`Address line 2`)} fieldName="address2" />
      <FlexBox spacing={6} direction={screenWidthMatch.md ? "row" : "column"}>
        <FormikTextInput label={t(`City`)} fieldName="city" required={isRequired} />
        <FormikTextInput label={t(`State`)} fieldName="state" />
      </FlexBox>
      <FlexBox spacing={6} direction={screenWidthMatch.md ? "row" : "column"}>
        <FormikTextInput label={t(`Country`)} fieldName="country" required={isRequired} />
        <FormikTextInput label={t(`Zip code`)} fieldName="zipCode" required={isRequired} />
      </FlexBox>
    </FlexBox>
  );
};

export default FormikManualAddressInput;
