import { enumFromValue } from "utils/enumFromValue";
import { DocumentStatus } from "./DocumentStatus.model";

export enum RequiredDocumentStatus {
  Required = "Required",
  Received = "Received",
  AwaitingReview = "Awaiting Review",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Withdrawn = "Withdrawn",
}

export enum MergeStatus {
  NotMerged = "NotMerged",
  InProgress = "InProgress",
  Merged = "Merged",
  Error = "Error",
}

export type IRequiredDocumentEntity = {
  applicationId: number;
  id: number;
  documentGuid: string;
  // TODO: Do we need to rename the category ?
  // category is not same as the document category
  category: string;
  name: string;
  description: string;
  status: RequiredDocumentStatus;
  documentContainerId: number;
  receivedDate?: string;
  acceptedDate?: string;
  fileName?: string;
  fileSize?: number;
  containerMergeStatus: MergeStatus;
};

export type IRequiredDocumentUploadEntity = {
  requiredDocumentGroupGuid: string;
  applicationDocumentId: number;
  name: string;
  documentStatus: DocumentStatus;
  councilStatus: RequiredDocumentStatus;
  receivedDate: string;
  reason: string;
  processingDate: string;
  processingDocumentGuid: string;
  fileName: string;
  fileSize: number;
  mimeType: string;
};

export const RequiredDocumentStatusUtil = {
  parse: (value: string): RequiredDocumentStatus => {
    // TODO: BLD-1987 typing issue with this function
    const parsedEnum: any = enumFromValue(RequiredDocumentStatus, value, RequiredDocumentStatus.Required);
    return parsedEnum as RequiredDocumentStatus;
  },
};

export const MergeStatusUtil = {
  parse: (value: string): MergeStatus => {
    // TODO: BLD-1987 typing issue with this function
    const parsedEnum: any = enumFromValue(MergeStatus, value, MergeStatus.NotMerged);
    return parsedEnum as MergeStatus;
  },
};
